import { type Parameters } from '../app/GenerationParameters'
import { type FileWithName } from '../app/SelectSpreadsheet'
import { callTagGenerator } from './call-tiger'
import { createOutputFiles } from './output'
import { parseInputFile } from './parse'
import { type Placement, type WrappedPlacement } from './types'

const mapPlacementsPerAdServerSiteName = (
  placements: WrappedPlacement[]
): Record<string, WrappedPlacement[]> => {
  const placementsPerAdServerSiteName: Record<string, WrappedPlacement[]> = {}

  placements.forEach(placement => {
    if (placementsPerAdServerSiteName[placement.adServerSiteName] == null) {
      placementsPerAdServerSiteName[placement.adServerSiteName] = []
    }

    placementsPerAdServerSiteName[placement.adServerSiteName].push(placement)
  })

  return placementsPerAdServerSiteName
}

const wrapPlacements = async (
  placements: Placement[],
  parameters: Parameters
): Promise<WrappedPlacement[]> =>
  await Promise.all(
    placements.map(async placement => {
      const dvDisplayTags = await callTagGenerator(placement, parameters)

      return {
        adServerSiteName: placement.AdServerSiteName,
        placementName: placement.PlacementName,
        placementId: placement.AdServerPlacementId,
        startDate: placement.StartDate,
        endDate: placement.EndDate,
        size: placement.UnitSize,
        dvDisplayTags
      }
    })
  )

export const performUmtWrapping = async (
  inputFile: FileWithName,
  parameters: Parameters
): Promise<FileWithName[]> => {
  const { placements } = await parseInputFile(inputFile)

  const wrappedPlacements = await wrapPlacements(placements, parameters)

  const placementsPerAdServerSiteName =
    mapPlacementsPerAdServerSiteName(wrappedPlacements)

  return createOutputFiles(
    parameters.campaignName,
    placementsPerAdServerSiteName
  )
}
