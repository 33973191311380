import pRetry from 'p-retry'

import { type Parameters } from '../app/GenerationParameters'
import { type Placement } from './types'

const TAG_GENERATOR_URL =
  'https://video.doubleverify.com/tag-generator/dv-video-omnitag/2/advertiser/display-html-wrapper/v1'

const httpPost = async (url: string, body: unknown): Promise<string> => {
  const result = await pRetry(
    () =>
      fetch(url, {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Zentrick-Application': 'display-tagging-panel'
        }),
        body: JSON.stringify(body)
      }),
    { retries: 3 }
  )

  return result.text()
}

type TagGeneratorBody = {
  adTag: string
  clientCode: string
  mediaPlan: {
    campaign?: string
    site?: string
    placement?: string
    advertiser: string
  }
  activationConfig: {
    tagExecutionModifiers: {
      disableClientSideBlocking?: boolean
      disableAdvertiserQualityMonitoring?: boolean
    }
    partnerHosted?: 'amazon'
    programmaticAnalytics?: { [key: string]: string }
    customParameters?: { [key: string]: string }
  }
}

export const callTagGenerator = async (
  placement: Placement,
  parameters: Parameters
): Promise<string> => {
  const body: TagGeneratorBody = {
    adTag: placement.AdTag,
    clientCode: parameters.clientId,
    mediaPlan: {
      campaign: placement.AdServerCampaignId,
      site: placement.AdServerSiteId,
      placement: placement.AdServerPlacementId,
      advertiser: parameters.advertiserName
    },
    activationConfig: {
      tagExecutionModifiers: {
        disableClientSideBlocking:
          !placement.Services.toLowerCase().includes('blocking'),
        disableAdvertiserQualityMonitoring:
          !placement.Services.toLowerCase().includes('monitoring')
      },
      partnerHosted: parameters.amazonHosted ? 'amazon' : undefined
    }
  }

  const res = await httpPost(TAG_GENERATOR_URL, body)
  const htmlSnippet = JSON.parse(res)?.htmlSnippet

  if (htmlSnippet == null || typeof htmlSnippet !== 'string') {
    throw new Error(
      `Could not read response from tag-generator, expected a string but got '${htmlSnippet}'`
    )
  }

  return htmlSnippet
}
