export enum PlacementFields {
  AdServerCampaignId = 'AdServerCampaignId',
  AdServer = 'AdServer',
  AdServerSiteId = 'AdServerSiteId',
  AdServerSiteName = 'AdServerSiteName',
  AdServerPlacementId = 'AdServerPlacementId',
  PlacementName = 'PlacementName',
  UnitSize = 'UnitSize',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  Services = 'Services',
  AdTag = 'AdTag'
}

export type Placement = Record<PlacementFields, string>

export enum PlacementFieldColumns {
  AdServerCampaignId = 'A',
  AdServer = 'B',
  AdServerSiteId = 'C',
  AdServerSiteName = 'D',
  AdServerPlacementId = 'E',
  PlacementName = 'F',
  UnitSize = 'G',
  StartDate = 'H',
  EndDate = 'I',
  Services = 'J',
  AdTag = 'K'
}

export type WrappedPlacement = {
  adServerSiteName: string
  placementName: string
  placementId: string
  startDate: string
  endDate: string
  size: string
  dvDisplayTags: string
}
