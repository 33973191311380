import { useState } from 'react'
import { Alert, Button, Col, Container, Row, Spinner } from 'react-bootstrap'

import { performUmtWrapping } from '../wrapping'
import GenerationParameters, { checkOptionsSetAndValid, type Parameters } from './GenerationParameters'
import Output, { generateOutput } from './Output'
import SelectSpreadsheet, { type FileWithName } from './SelectSpreadsheet'

const App = () => {
  const [parameters, setParameters] = useState<Parameters>({
    advertiserName: '',
    campaignName: '',
    clientId: '',
    amazonHosted: true
  })
  const [inputFile, setInputFile] = useState<FileWithName | null>(null)
  const [output, setOutput] = useState<FileWithName[] | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <SelectSpreadsheet setInputFile={setInputFile} inputFile={inputFile} />
        </Col>
      </Row>

      <Row>
        <Col>
          <GenerationParameters parameters={parameters} setParameters={setParameters} />
        </Col>
        <Col sm={{ span: 10, offset: 2 }}>
          <Button
            onClick={() =>
              generateOutput(inputFile!, parameters, setOutput, setError, setLoading, performUmtWrapping)
            }
            disabled={inputFile == null || loading || !checkOptionsSetAndValid(parameters)}
          >
            {loading ? (
              <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              <></>
            )}
            Submit
          </Button>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>{output != null ? <Output files={output} parameters={parameters} /> : <></>}</Col>
      </Row>

      {error != null ? (
        <Row className="mt-4">
          <Col>
            <Alert variant="danger" data-testid="alert-error">
              <Alert.Heading>Something went wrong!</Alert.Heading>

              <ul>
                <li>Are you connected to the internet?</li>
                <li>Are you uploading a valid Excel file?</li>
                <li>Is the Excel file in the correct format?</li>
                <li>Are you using an ad blocker of some kind?</li>
              </ul>

              <h5>Error details</h5>
              <pre>
                <code>{error}</code>
              </pre>

              <h5>Generation parameters</h5>
              <pre>
                <code>{JSON.stringify(parameters, null, 2)}</code>
              </pre>
            </Alert>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Container>)
}

export default App
