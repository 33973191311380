import { type Dispatch, type SetStateAction, useCallback } from 'react'
import Card from 'react-bootstrap/Card'
import { useDropzone } from 'react-dropzone'

export type FileWithName = {
  name: string
  contents: ArrayBuffer
}

const loadFile = (file: File) =>
  new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.result == null) {
        reject(new Error('Reader result was null'))
      } else if (!(reader.result instanceof ArrayBuffer)) {
        reject(new Error('Reader result is not an ArrayBuffer'))
      } else {
        resolve(reader.result)
      }
    }
    reader.onerror = (err) => reject(err)
    reader.readAsArrayBuffer(file)
  })

const footer = (inputFile: FileWithName | null) => {
  if (inputFile == null) {
    return <></>
  }

  return <Card.Footer className="text-muted">Selected: {inputFile.name}</Card.Footer>
}

const SelectSpreadsheet = ({
  inputFile,
  setInputFile
}: {
  inputFile: FileWithName | null
  setInputFile: Dispatch<SetStateAction<FileWithName | null>>
}) => {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const firstFile = acceptedFiles[0]
      if (firstFile == null) {
        return
      }

      setInputFile({ name: firstFile.name, contents: await loadFile(firstFile) })
    },
    [setInputFile]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    }
  })

  return (
    <Card {...getRootProps()}>
      <Card.Body>
        <Card.Title>Input file</Card.Title>
        <Card.Text>
          {isDragActive ? (
            <>Drop the files here ...</>
          ) : (
            <>Drag 'n' drop an input file here, or click to select a file</>
          )}

          <input {...getInputProps()} />
        </Card.Text>
      </Card.Body>

      {footer(inputFile)}
    </Card>
  )
}

export default SelectSpreadsheet
