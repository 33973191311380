import JSZip from 'jszip'
import Card from 'react-bootstrap/Card'
import DownloadLink from 'react-download-link'

import { dateString } from '../util/date-string'
import { type Parameters } from './GenerationParameters'
import excelIcon from './images/excel.svg'
import zipIcon from './images/zip.svg'
import { type FileWithName } from './SelectSpreadsheet'

const downloadAllAsZip = (files: FileWithName[]): Promise<ArrayBuffer> => {
  const zipFile = new JSZip()
  files.forEach((file) => zipFile.file(file.name, file.contents))

  return zipFile.generateAsync({ type: 'arraybuffer' })
}

export const generateOutput = async (
  inputFile: FileWithName,
  parameters: Parameters,
  setOutput: React.Dispatch<React.SetStateAction<FileWithName[] | null>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  handler: (inputFile: FileWithName, parameters: Parameters) => Promise<FileWithName[]>
): Promise<void> => {
  try {
    setOutput(null)
    setError(null)
    setLoading(true)

    const result = await handler(
      inputFile, parameters
    )

    setOutput(result)
    setError(null)
    setLoading(false)
  } catch (err: any) {
    console.error(err)
    setOutput(null)
    setLoading(false)
    setError(`${err.message}\n\n${err.stack}`)
  }
}

const Output = ({ files, parameters }: { files: FileWithName[], parameters: Parameters }) => {
  const zipFileName = `DV Tags_${parameters.campaignName}_${parameters.advertiserName}_${parameters.clientId}_${dateString(
    new Date()
  )}.zip`

  const cards = files.map((file) => (
    <Card key={file.name} className="mt-4">
      <Card.Body>
        <Card.Title>
          <img width={24} height={24} className="mr-3" src={excelIcon} alt="Excel icon" />
          {file.name}
        </Card.Title>
        <DownloadLink label="Click here to download" filename={file.name} exportFile={() => file.contents} />
      </Card.Body>
    </Card>
  ))

  return (
    <div data-testid="output-container">
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>
            <img width={24} height={24} className="mr-3" src={zipIcon} alt="Excel icon" />
            Download all files as a ZIP file
          </Card.Title>
          <DownloadLink
            label="Download all files as a ZIP file"
            filename={zipFileName}
            exportFile={() => downloadAllAsZip(files)}
          />
        </Card.Body>
      </Card>
      {cards}
    </div>
  )
}

export default Output