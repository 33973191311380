import { Workbook, type Worksheet } from 'exceljs'

import { type FileWithName } from '../app/SelectSpreadsheet'
import { dateString } from '../util/date-string'
import { type WrappedPlacement } from './types'

const writeRow = (
  sheet: Worksheet,
  placement: WrappedPlacement,
  rowIndex: number
): void => {
  const rowOffset = 11

  const sheetRow = sheet.getRow(rowOffset + rowIndex)
  sheetRow.numFmt = '@'

  sheetRow.getCell(2).value = placement.placementName
  sheetRow.getCell(3).value = placement.placementId
  sheetRow.getCell(4).value = placement.startDate
  sheetRow.getCell(5).value = placement.endDate
  sheetRow.getCell(6).value = placement.size
  sheetRow.getCell(7).value = placement.dvDisplayTags
}

const createOutputFileForAdServerSite = async (
  campaignName: string,
  adServerSiteName: string,
  wrappedPlacements: WrappedPlacement[],
  template: ArrayBuffer
): Promise<FileWithName> => {
  const workbook = new Workbook()
  await workbook.xlsx.load(template)

  const sheet = workbook.worksheets[0]

  sheet.getRow(2).getCell(3).value = adServerSiteName
  sheet.getRow(3).getCell(3).value = campaignName

  wrappedPlacements.forEach((placement, idx) => writeRow(sheet, placement, idx))

  return {
    name: `DV Tags_${campaignName}_${adServerSiteName}_${dateString(
      new Date()
    )}.xlsx`,
    contents: await workbook.xlsx.writeBuffer()
  }
}

export const createOutputFiles = async (
  campaignName: string,
  wrappedPlacementsPerAdServerSiteName: Record<string, WrappedPlacement[]>
): Promise<FileWithName[]> => {
  const response = await fetch(`${process.env.PUBLIC_URL}/output-template.xlsx`)
  const template = await response.arrayBuffer()
  return Promise.all(
    Object.entries(wrappedPlacementsPerAdServerSiteName).map(
      async ([adServerSiteName, wrappedPlacements]) =>
        await createOutputFileForAdServerSite(
          campaignName,
          adServerSiteName,
          wrappedPlacements,
          template
        )
    )
  )
}
