import { Workbook, type Worksheet } from 'exceljs'

import { type FileWithName } from '../app/SelectSpreadsheet'
import { type Placement, PlacementFieldColumns, PlacementFields } from './types'

const HEADER_ROW_NUMBER = 3

const getCellOrEmtpyString = (sheet: Worksheet, cell: string): string => {
  const value = sheet.getCell(cell)?.value
  const stringValue = value?.toString()

  if (stringValue == null) {
    return ''
  } else if (value instanceof Date) {
    return `${value.getUTCDate()}/${
      value.getUTCMonth() + 1
    }/${value.getUTCFullYear()}`
  }
  return stringValue
}

const getCampaignId = (sheet: Worksheet): string => {
  if (sheet.getCell('A1').value !== 'Campaign ID') {
    throw new Error('Expected cell A1 to be "Campaign ID"')
  }

  const campaignIdCell = sheet.getCell('B1')
  if (campaignIdCell.value == null || campaignIdCell.value.toString() === '') {
    throw new Error('Campaign ID is missing')
  }
  return campaignIdCell.value.toString()
}

const getPlacements = (sheet: Worksheet): Placement[] => {
  const placements: Placement[] = []

  for (let i = HEADER_ROW_NUMBER + 1; i <= sheet.rowCount; i++) {
    const placement: Placement = {
      [PlacementFields.AdServerCampaignId]: getCellOrEmtpyString(
        sheet,
        `${PlacementFieldColumns.AdServerCampaignId}${i}`
      ),
      [PlacementFields.AdServer]: getCellOrEmtpyString(
        sheet,
        `${PlacementFieldColumns.AdServer}${i}`
      ),
      [PlacementFields.AdServerSiteId]: getCellOrEmtpyString(
        sheet,
        `${PlacementFieldColumns.AdServerSiteId}${i}`
      ),
      [PlacementFields.AdServerSiteName]: getCellOrEmtpyString(
        sheet,
        `${PlacementFieldColumns.AdServerSiteName}${i}`
      ),
      [PlacementFields.AdServerPlacementId]: getCellOrEmtpyString(
        sheet,
        `${PlacementFieldColumns.AdServerPlacementId}${i}`
      ),
      [PlacementFields.PlacementName]: getCellOrEmtpyString(
        sheet,
        `${PlacementFieldColumns.PlacementName}${i}`
      ),
      [PlacementFields.UnitSize]: getCellOrEmtpyString(
        sheet,
        `${PlacementFieldColumns.UnitSize}${i}`
      ),
      [PlacementFields.StartDate]: getCellOrEmtpyString(
        sheet,
        `${PlacementFieldColumns.StartDate}${i}`
      ),
      [PlacementFields.EndDate]: getCellOrEmtpyString(
        sheet,
        `${PlacementFieldColumns.EndDate}${i}`
      ),
      [PlacementFields.Services]: getCellOrEmtpyString(
        sheet,
        `${PlacementFieldColumns.Services}${i}`
      ),
      [PlacementFields.AdTag]: getCellOrEmtpyString(
        sheet,
        `${PlacementFieldColumns.AdTag}${i}`
      )
    }

    placements.push(placement)
  }

  return placements
}

export const parseInputFile = async (
  inputFile: FileWithName
): Promise<{
  campaignId: string
  placements: Placement[]
}> => {
  const workbook = new Workbook()

  await workbook.xlsx.load(inputFile.contents)

  const sheet = workbook.worksheets[0]

  const campaignId = getCampaignId(sheet)

  const placements = getPlacements(sheet)

  return { campaignId, placements }
}
