import { type Dispatch, type SetStateAction } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export type Parameters = {
  advertiserName: string,
  campaignName: string,
  clientId: string
  amazonHosted: boolean
}

export const validateClientId = (id: string): boolean => /^[0-9]+$/.test(id)

export const checkOptionsSetAndValid = (parameters: Parameters): boolean =>
  parameters.advertiserName !== '' &&
  parameters.campaignName !== '' &&
  parameters.clientId !== '' &&
  validateClientId(parameters.clientId)

const GenerationParameters = ({
  parameters,
  setParameters
}: {
  parameters: Parameters
  setParameters: Dispatch<SetStateAction<Parameters>>
}) => (
  <div>
    <Form>
      <Form.Group as={Row} controlId="formClientId">

        <Form.Label column sm={2}>
            Client Id
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            required
            value={parameters.clientId}
            onChange={(event) =>
              setParameters({ ...parameters, clientId: event.target.value })
            }
            type="text"
            placeholder="Client Id"
          />
          {parameters.clientId === '' ? <Form.Text className="text-muted">Required</Form.Text> : <></>}
          {!validateClientId(parameters.clientId) ? (
            <Form.Text className="text-muted"> Must be a number</Form.Text>
          ) : (
            <></>
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formCampaign">
        <Form.Label column sm={2}>
            Campaign Name
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            required
            value={parameters.campaignName}
            onChange={(event) =>
              setParameters({ ...parameters, campaignName: event.target.value })
            }
            type="text"
            placeholder="Campaign Name"
          />
          {parameters.campaignName === '' ? <Form.Text className="text-muted">Required</Form.Text> : <></>}
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formAdvertiser">
        <Form.Label column sm={2}>
            Advertiser Name
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            required
            value={parameters.advertiserName}
            onChange={(event) =>
              setParameters({ ...parameters, advertiserName: event.target.value })
            }
            type="text"
            placeholder="Advertiser Name"
          />
          {parameters.advertiserName === '' ? <Form.Text className="text-muted">Required</Form.Text> : <></>}
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formAmazonHosted">
        <Form.Label column sm={2}>
            Amazon Hosted
        </Form.Label>
        <Col sm={10}>
          <Form.Check
            required
            defaultChecked={parameters.amazonHosted}
            onChange={() =>
              setParameters({ ...parameters, amazonHosted: !parameters.amazonHosted })
            }
            placeholder="Advertiser Name"
          />
          {parameters.advertiserName === '' ? <Form.Text className="text-muted">Required</Form.Text> : <></>}
        </Col>
      </Form.Group>

    </Form>
  </div>
)

export default GenerationParameters
